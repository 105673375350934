<template>
    <div class="BackupRestore full-height pa-4 d-flex flex-column ">
        <div v-if="showProgress" class="loaderOverlay full-height d-flex flex-column align-center justify-center pb-6">
            <div class="message pb-6">
                <p>{{activity}}</p>
            </div>
            <v-progress-linear
                color="disiIrisblue"
                :value=progress
                background-color="disiDarkblue"
                height="12"
                buffer-value="0"
                stream
            ></v-progress-linear>
        </div>
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 flex-grow-1 bg-white ">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="productsTableHeaders"
                    :items="this.backuplist"
                    sortBy='dateTime'
                    sort-desc
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" :id="item.id" :class="{'selected': item.id === selectedId}" @click="selectedId = item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.dateTime }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToBackup" :class="{'disabled': disableBack}"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="restoreBackup" :successCheck="showRestoreSuccess" :progress="showRestoreProgress" :class="{'disabled': selectedId === 0}" @footerButtonClick="restoreBackup"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";

export default {
    name: 'BackupRestore',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            selectedId: 0,
            showProgress: false,
            progress: 0,
            activity: '',
            showRestoreProgress: false,
            showRestoreSuccess: false,
            disableBack: false,
        }
    },
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/backups/list', 'backuplist']);
    },
    computed: {
        ...mapState([
            'backuplist',
            'userlist',
        ]),
        productsTableHeaders(){
            return [
                { text: this.$t('general.name'), align: 'start', value: 'name' },
                { text: this.$t('system.backup.performedBy'), value: 'by' },
                { text: this.$t('general.date'), value: 'dateTime' },
            ]
        },
    },
    methods: {
        goToBackup(){
            this.$router.push('backup');
        },

        getProgress(){
            this.$store.dispatch('getAxiosNoSetter', '/admin/backups/progress')
                .then(response=>{
                    if (response.status === 200 && this.$router.currentRoute.name == 'BackupRestore'){
                        if (response.data.progress < 100){
                            this.progress = response.data.progress;
                            this.activity = response.data.activity;
                            setTimeout(()=>{
                                this.getProgress();
                            }, 500)
                        } else{
                            this.$store.dispatch('getAxiosSetter', ['/admin/backups/list', 'backuplist']);
                            this.disableBack = false;
                            this.showProgress = false;
                            this.showRestoreProgress = false;
                            this.showRestoreSuccess = true;
                            setTimeout(()=>{
                                this.showRestoreSuccess = false;
                            }, 1600)
                        }
                    } else {
                        this.showProgress = false;
                        this.showRestoreProgress = false;
                        this.disableBack = false;
                    }
                })
        },
        restoreBackup(){
            this.$confirm({message: this.$t('alerts.restoreBackup'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/admin/backups/restore', {'id': parseInt(this.selectedId)}])
                        .then(response=>{
                            if (response.status === 200) {
                                this.selectedId = 0;
                                this.showProgress = true;
                                this.disableBack = true;
                                this.showRestoreProgress = true;
                                this.getProgress();
                            }
                        })
                })
                .catch(() => {
                    // cancel
                });
        },
    },
    beforeRouteLeave (to, from, next) {
        if(!this.showProgress){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.backupInProgress'), show: true, okButton: true })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
.BackupRestore .theme--light.v-data-table .v-data-table__wrapper > table tbody tr.selected{
    background-color: $disiIrisblue!important;
    td{
        color: white;
    }
}
</style>